@btn-font-weight: 300;
@font-family-sans-serif: "Lato", Helvetica, Arial, sans-serif;
@bgcolor: #e9e9ea;
@bradius1: 7px; 
@navbg: rgb(29,51,73);
@primary-font: 'Lato';
@secondary-font: 'Avenir-Medium';

@import "bootstrap/bootstrap";
@import "nav.less";

body, label, .checkbox label {
	font-weight: 400;
};

html {
	position: relative;
	min-height: 100%;
};

body {
	background: @bgcolor;
	margin-bottom: 60px;
}

.welcome h1 {
	margin-top: 30px;
	color: rgb(60,60,60);
	font-family: 'Lato';
	letter-spacing: -1px;
	margin-left:0;
}

.welcome {
	background: white;
	min-height: 600px;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 7px;
}

.welcome-transparent {
	min-height: 600px;
	border-radius: 7px;
}

.section {
	border-radius: 5px;
	font-family: 'Lato', sans-serif;
	background: white;
	border: 1px solid rgb(227,227,227);
	border-bottom: 0;
	text-align:center;
	padding: 25px;
	margin-bottom: 15px;
	box-shadow: 0px 1px 0px rgba(0,0,0,0.2);
	margin-top: 15px;
	p {
		text-align:justify;
		color:rgb(60,60,60);
		line-height: 1.6em;
		-webkit-font-smoothing: antialiased;
		font-size: 1.2em;
		margin: 0;
	};
};

.demorow, .goodluckrow {
  padding-top: 10px;
  padding-bottom: 10px;
  background: #e0781c;
  -webkit-font-smoothing: antialiased;
  color: white;
  margin-top: -20px;
  font-family: 'Lato', sans-serif;
  font-size: 1.1em;
  margin-bottom: 15px;
  text-shadow: 0px 1px 0px rgba(0,0,0,0.1);
  a {
  	color: inherit;
  	border-bottom: 1px solid white;
  }
  a:hover {
  	border-bottom: none;
  }
};

.goodluckrow {
	background: rgb(0,175,53);
}

.titlerow {
	  padding-top: 30px;
  padding-bottom: 30px;
  background: orange;
  -webkit-font-smoothing: antialiased;
  text-shadow: 0px 1px 0px rgba(0,0,0,1);
  color: white;
  margin-top: -20px;
  font-family: 'Lato', sans-serif;
  font-size: 2em;
  background: linear-gradient(
      rgba(0, 0, 10, 0.4), 
      rgba(0, 0, 10, 0.4)
    ), url('../images/green-temple.jpg');
  background-position-y: 190px;
  background-size: cover;
  margin-bottom: 15px;
};

.top-box{
	p {
		margin-bottom: 0 !important;
	}
}

.footer {
  position: absolute;
  bottom: 0;
  left:0;
  font-family: 'Lato', sans-serif;
  height: 60px;
  width: 100%;
  padding-top: 15px;
  font-size: 1em;
  -webkit-font-smoothing:antialiased;
  .right {
		text-align:right;
	};
	.middle {
		text-align:center;
	}
	a {
		color: inherit;
		border-bottom: 1px dotted gray;
	}
	a:hover {
		border-bottom: 1px solid gray;
	}
	.right-link {
		margin-left: 10px;
	}
};

.alert-danger {
	font-family: 'Lato', sans-serif;
	font-size: 1.1em;
	line-height: 160%;
	-webkit-font-smoothing:antialiased
}

.bursaries-list {
	margin-top: 20px;
	margin-bottom: 20px;
	text-align:left;
	font-size: 1.2em;
	line-height: 170%;
	-webkit-font-smoothing:antialiased;
}

.company-details p {
	text-align: left !important;
}

@media(max-width: 910px) {
	.footer {
		text-align: center;

	}
	.footer-right {
		text-align: center;
		width: 100%;
	}
}

.question-form-wrapper {
	min-height: 550px;
}

@media(max-width: 768px) {
	.dropdown-menu li a {
		color: rgba(255,255,255,0.7);
	}
}

@media(max-width: 480px) {
	.question-form-wrapper {
		min-height: 600px;
	}
}